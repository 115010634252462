import React from "react"
import Section from "../Section/Section"
import "./news-style.scss"
import { graphql, useStaticQuery } from "gatsby"

const News = () => {
  const { allWordpressWpDescriptionTexts } = useStaticQuery(
    graphql`
      query {
        allWordpressWpDescriptionTexts {
          edges {
            node {
              title
              acf {
                description
              }
            }
          }
        }
      }
    `
  )

  const { title, acf } = allWordpressWpDescriptionTexts.edges[4].node

  return (
    <Section id="news" title={title} subtitle={acf.description} padding="small">
      <div className="news-feeds">
        <div className="news-twitter">
          <h2 className="section-title left-align small-padding-bottom">
            Tweets
          </h2>
          <div className="elfsight-app-0efe7e58-0a42-465b-b497-b6fcbe6d2ce3"></div>
        </div>
        <div className="news-instagram">
          <h2 className="section-title left-align small-padding-bottom">
            Grams
          </h2>
          <div className="elfsight-app-471595cf-5073-4f71-ad99-a661101d5072"></div>
        </div>
      </div>
    </Section>
  )
}

export default News
