import React, { useContext, useEffect, useState, useRef } from "react"
import c from "classnames"
import { isMobile } from "react-device-detect"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../Section/Section"
import Social from "../Social/Social"
import { ModalContext } from "../../pages/index.js"
import "./speakers-style.scss"

const MobileSelectedSpeakerContext = React.createContext()

const Speakers = () => {
  const speakersRef = useRef()
  const [mouseIsDown, setMouseIsDown] = useState(false)
  const [startX, setStartX] = useState()
  const [scrollLeft, setScrollLeft] = useState()
  const [showArrow, setShowArrow] = useState(true)

  const handleMouseDown = e => {
    setMouseIsDown(true)
    setStartX(e.pageX - speakersRef.current.offsetLeft)
    setScrollLeft(speakersRef.current.scrollLeft)
  }

  const handleMouseMove = e => {
    if (!mouseIsDown) return
    e.preventDefault()
    const x = e.pageX - speakersRef.current.offsetLeft
    const walk = (x - startX) * 1.4 //scroll-fast
    speakersRef.current.scrollLeft = scrollLeft - walk
  }

  const [mobileSelectedSpeaker, setMobileSelectedSpeaker] = useState()

  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpSpeakers {
            edges {
              node {
                title
                acf {
                  speaker_title
                  speaker_company
                  speaker_industry
                  speaker_bio
                  speaker_website
                  speaker_video
                  speaker_facebook
                  speaker_instagram
                  speaker_linkedin
                  speaker_twitter
                }
                featured_media {
                  localFile {
                    childImageSharp {
                      fixed(width: 340, height: 410) {
                        base64
                        tracedSVG
                        aspectRatio
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={props => {
        const { edges } = props.allWordpressWpSpeakers

        return (
          <Section title={"Speakers"}>
            <span
              className={c(
                "speakers-swipe-arrow",
                showArrow && "speakers-swipe-arrow-show"
              )}
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M50 25L0 50V0z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </span>
            <ul
              ref={speakersRef}
              className={c("speakers-cards", mouseIsDown && "mousedown-active")}
              onMouseDown={e => handleMouseDown(e)}
              onMouseUp={() => setMouseIsDown(false)}
              onMouseLeave={() => setMouseIsDown(false)}
              onMouseMove={e => handleMouseMove(e)}
              onTouchStart={() => setShowArrow(false)}
              onScroll={() => setShowArrow(false)}
            >
              <MobileSelectedSpeakerContext.Provider
                value={{ mobileSelectedSpeaker, setMobileSelectedSpeaker }}
              >
                {edges.map((speaker, i) => {
                  return <Speaker speaker={speaker.node} key={i} />
                })}
              </MobileSelectedSpeakerContext.Provider>
            </ul>
          </Section>
        )
      }}
    />
  )
}

export const Speaker = ({ speaker }) => {
  if (!speaker.featured_media) return null

  const value = useContext(ModalContext)
  // const parseYouTubeLink = url =>
  //   "https://www.youtube.com/embed/" + url.split("=")[1]

  const styleTitle = title => {
    const array = title.split(" ")
    const surname = array.pop()
    const firstNames = array.toString().replace(/,/g, " ")
    return (
      <h3 className="speaker-title">
        {firstNames}
        <strong> {surname}</strong>
      </h3>
    )
  }

  const {
    speaker_bio,
    speaker_company,
    speaker_industry,
    speaker_title,
    speaker_video,
    speaker_facebook,
    speaker_instagram,
    speaker_linkedin,
    speaker_twitter,
  } = speaker.acf

  let hasSocialLinks
  if (
    speaker_facebook ||
    speaker_instagram ||
    speaker_linkedin ||
    speaker_twitter
  )
    hasSocialLinks = true
  else hasSocialLinks = false

  const socialProps = {
    facebook: speaker_facebook,
    instagram: speaker_instagram,
    linkedin: speaker_linkedin,
    twitter: speaker_twitter,
  }

  // const handlePlayVideo = (e, url) => {
  //   if (e.target.tagName === "DIV") {
  //     value.setModalState({
  //       isOpen: true,
  //       YouTubeURL: parseYouTubeLink(url),
  //     })
  //   }
  // }

  const selectedContext = useContext(MobileSelectedSpeakerContext)

  return (
    <li
      className={c(
        "speaker-card",
        selectedContext &&
          selectedContext.mobileSelectedSpeaker === speaker.title
          ? "speaker-card-mobile-selected"
          : null
      )}
    >
      <div className="speaker-overlay" />
      <Img
        className="speaker-image"
        fixed={speaker.featured_media.localFile.childImageSharp.fixed}
        height={410}
        width={340}
      />
      <span className="speaker-industry">{speaker_industry}</span>
      <div
        className={c(
          "speaker-content",
          speaker_video ? "hover-video" : "hover-bio"
        )}
      >
        <div className="speaker-name-and-titles">
          {speaker_video ? (
            <p className="speaker-watch-video">Watch Video</p>
          ) : (
            <p className="speaker-watch-video">Bio</p>
          )}
          {styleTitle(speaker.title)}
          <p className="speaker-titles">
            {speaker_title}
            <br />
            {speaker_company}
          </p>
        </div>
        <div className="speaker-social">
          {hasSocialLinks && <Social platforms={socialProps} />}
        </div>
        {!speaker_video && speaker_bio && (
          <div className="speaker-hover-text">
            <p className="speaker-bio">{speaker_bio}</p>
          </div>
        )}
      </div>
      {speaker_video && (
        <div className="speaker-video-hover">
          <svg
            width="94px"
            height="94px"
            viewBox="0 0 94 94"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                transform="translate(-865.000000, -2917.000000)"
                fill="currentColor"
              >
                <polygon
                  transform="translate(912.000000, 2964.000000) rotate(-270.000000) translate(-912.000000, -2964.000000) "
                  points="912 2917 959 3011 865 3011"
                ></polygon>
              </g>
            </g>
          </svg>
        </div>
      )}
    </li>
  )
}

export default Speakers
