import React, { useContext } from "react"
import c from "classnames"
import { graphql, StaticQuery } from "gatsby"
import "./main-menu-style.scss"
import Scrollspy from "react-scrollspy"
import Scrollchor from "react-scrollchor"
import { scrollAnimation } from "../../constants/constants"
import { ActiveSectionContext } from "../../pages/index.js"

const MainMenu = () => {
  const value = useContext(ActiveSectionContext)

  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpApiMenusMenusItems(
            filter: { name: { eq: "Main menu" } }
          ) {
            edges {
              node {
                items {
                  title
                  url
                }
              }
            }
          }
        }
      `}
      render={props => {
        const { items } = props.allWordpressWpApiMenusMenusItems.edges[0].node
        let itemsId = ["intro"]
        items.map(item => itemsId.push(item.url.replace(/#/, "")))
        itemsId.push("newsletter")
        itemsId.push("media-queries")

        return (
          <Scrollspy
            items={itemsId}
            onUpdate={item => item && value.setActiveSection("#" + item.id)}
            componentTag="nav"
            offset={-100}
          >
            {items.map(item => {
              return (
                <Scrollchor
                  to={item.url}
                  animate={scrollAnimation}
                  beforeAnimate={() => value.setActiveSection(item.url)}
                  className={
                    value && value.activeSection === item.url && "active"
                  }
                  key={item.title}
                  disableHistory
                >
                  {item.title}
                </Scrollchor>
              )
            })}
            <Scrollchor
              to="newsletter"
              animate={scrollAnimation}
              beforeAnimate={() => value.setActiveSection("#newsletter")}
              className={c(
                "main-menu-newsletter-button",
                value && value.activeSection === "#newsletter" && "active"
              )}
              disableHistory
            >
              Newsletter
            </Scrollchor>
          </Scrollspy>
        )
      }}
    />
  )
}

export default MainMenu
