import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Scrollchor from "react-scrollchor"
import { scrollAnimation } from "../../constants/constants"
import WhatsApp from "../WhatsApp/WhatsApp"
import "./footer-style.scss"

const Footer = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allWordpressAcfFooter {
          edges {
            node {
              acf {
                socials_title
                facebook
                instagram
                twitter
                linkedin
                partners_title
                footer_logo_title
                footer_logo_website_link
                newsletter_title
                newsletter_button_text
                partners {
                  partner_name
                  partner_website_link
                }
                footer_logo {
                  localFile {
                    childImageSharp {
                      fixed(width: 180, height: 98) {
                        base64
                        tracedSVG
                        aspectRatio
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const { acf: data } = query.allWordpressAcfFooter.edges[0].node
  const { facebook, instagram, linkedin, twitter } = data

  return (
    <footer>
      <div className="mobile-newsletter">
        <Scrollchor
          className="footer-newsletter-button"
          to={"#newsletter"}
          animate={scrollAnimation}
          disableHistory
        >
          {data.newsletter_button_text}
        </Scrollchor>
        <br />
        <br />
        <br />
        or
      </div>
      <WhatsApp />
      <div className="footer-content">
        <div className="footer-column">
          <h4 className="footer-newsletter-title">{data.footer_logo_title}</h4>
          <a
            href={data.footer_logo_website_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              className="main-partner-image main-partner-logo"
              fixed={data.footer_logo.localFile.childImageSharp.fixed}
            />
          </a>
        </div>
        <div className="footer-column">
          <h4>{data.socials_title}</h4>
          {facebook && (
            <li>
              <a href={facebook} target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </li>
          )}
          {instagram && (
            <li>
              <a href={instagram} target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </li>
          )}
          {linkedin && (
            <li>
              <a href={linkedin} target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </li>
          )}
          {twitter && (
            <li>
              <a href={twitter} target="_blank" rel="noopener noreferrer">
                Twitter
              </a>
            </li>
          )}
        </div>
        <div className="footer-column">
          <h4>{data.partners_title}</h4>
          {data.partners.map((partner, i) => {
            return (
              <li key={i}>
                <a
                  href={partner.partner_website_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {partner.partner_name}
                </a>
              </li>
            )
          })}
        </div>
        <div className="footer-column">
          <h4 className="footer-newsletter-title">{data.newsletter_title}</h4>
          <Scrollchor
            className="footer-newsletter-button"
            to={"#newsletter"}
            animate={scrollAnimation}
            disableHistory
          >
            {data.newsletter_button_text}
          </Scrollchor>
        </div>
      </div>
    </footer>
  )
}

export default Footer
