import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "./whatsapp-style.scss"

const WhatsApp = () => {
  const { allWordpressWpWhatsapp } = useStaticQuery(
    graphql`
      query {
        allWordpressWpWhatsapp {
          edges {
            node {
              content
            }
          }
        }
      }
    `
  )

  const { content } = allWordpressWpWhatsapp.edges[0].node

  const markup = content.replace(
    /a href/g,
    `a target="_blank" rel="noopener noreferrer" href`
  )

  return (
    <div className="whatsapp" dangerouslySetInnerHTML={{ __html: markup }} />
  )
}

export default WhatsApp
