import React, { useEffect, useState } from "react"
import ReactGA from "react-ga"
import "./cookies-style.scss"

const Cookies = () => {
  const [showCookie, setShowCookie] = useState()

  useEffect(() => {
    if (localStorage.getItem("acceptedCookie")) {
      setShowCookie(false)
      ReactGA.initialize("UA-141199451-3")
      ReactGA.pageview(window.location.pathname + window.location.search)
    } else setShowCookie(true)
  }, [])

  const handleSetCookies = () => {
    localStorage.setItem("acceptedCookie", true)
    setShowCookie(false)
    ReactGA.initialize("UA-141199451-3")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  return (
    <>
      {showCookie && (
        <div className="cookies">
          <h3>We use cookies</h3>
          <p>
            We use cookies on this website to primarily to track traffic and see
            how our users interact with the website.
          </p>
          <p>
            By continuing to use the website you understand we will be tracking
            from this point onwards.
          </p>
          <button className="button" onClick={() => handleSetCookies()}>
            <div className="cookies-consent-wrapper">
              I understand
              <span className="cookie-emoji">🍪</span>
            </div>
          </button>
        </div>
      )}
    </>
  )
}

export default Cookies
