import React, { useRef, useState, useEffect } from "react"
import c from "classnames"
import ResizeObserver from "resize-observer-polyfill"
import { animated, useSpring } from "react-spring"

const useMeasure = () => {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  )
  useEffect(() => (ro.observe(ref.current), ro.disconnect), [])
  return [{ ref }, bounds]
}

const Accordion = ({ buttonText, text }) => {
  const [on, toggle] = useState(false)
  const [bind, { height, top }] = useMeasure()
  const animation = useSpring({
    overflow: "hidden",
    height: on ? height + 4 + top * 2 : 0,
  })

  return (
    <>
      <button className="intro-accordion-button" onClick={() => toggle(!on)}>
        <div className={c(on && "accordion-open")}>&#9650;</div> {buttonText}
      </button>
      <animated.div style={animation}>
        <div
          {...bind}
          className="intro-accordion"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      </animated.div>
    </>
  )
}

export default Accordion
