import React from "react"
import c from "classnames"
import "./section-style.scss"

const Section = ({ children, description, id, padding, subtitle, title }) => {
  return (
    <section id={id ? id : title.replace(/\s+/g, "-").toLowerCase()}>
      <h2 className={c(!subtitle && "section-title")}>{title}</h2>
      {subtitle && (
        <h5
          className={c(
            "section-subtitle",
            padding === "small" && "small-padding-bottom"
          )}
        >
          {subtitle}
        </h5>
      )}
      {description !== "string" && description ? (
        <p className="section-description">{description}</p>
      ) : (
        <>
          {description &&
            description.map((paragraph, i) => {
              return (
                <p className="section-description" key={i}>
                  {paragraph}
                </p>
              )
            })}
        </>
      )}
      {children}
    </section>
  )
}

export default Section
