import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Intro from "../components/Intro/Intro"
import News from "../components/News/News"
import MainPartner from "../components/MainPartner/MainPartner"
import MediaContact from "../components/MediaContact/MediaContact"
import Modal from "../components/Modal/Modal"
import Newsletter from "../components/Newsletter/Newsletter"
import UpNextANDProgram from "../components/UpNextANDProgram/UpNextANDProgram"
import Speakers from "../components/Speakers/Speakers"
import Startups from "../components/Startups/Startups"
import TechPartners from "../components/TechPartners/TechPartners"
import SupportingPartners from "../components/SupportingPartners/SupportingPartners"
import WhatsApp from "../components/WhatsApp/WhatsApp"
import SEO from "../components/seo"

export const ModalContext = React.createContext()
export const ActiveSectionContext = React.createContext()

const IndexPage = () => {
  const [activeSection, setActiveSection] = useState("")
  const [modalState, setModalState] = useState({
    isOpen: false,
  })

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://apps.elfsight.com/p/platform.js"
    script.id = "elfsight"
    document.body.appendChild(script)
  }, [])

  return (
    <ActiveSectionContext.Provider value={{ activeSection, setActiveSection }}>
      <ModalContext.Provider
        value={{
          isOpen: modalState.isOpen,
          YouTubeURL: modalState.YouTubeURL,
          selectedPartner: modalState.selectedPartner,
          setModalState: setModalState,
        }}
      >
        <Layout>
          <SEO />
          <Intro />
          <UpNextANDProgram />
          <News />
          <Speakers />
          <Startups />
          <MainPartner />
          <TechPartners />
          <SupportingPartners />
          <Newsletter />
          <MediaContact />
        </Layout>
        <Modal />
      </ModalContext.Provider>
    </ActiveSectionContext.Provider>
  )
}

export default IndexPage
