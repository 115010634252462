import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Section from "../Section/Section"
import "./media-contact-style.scss"

const MediaContact = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpMediaContact {
            edges {
              node {
                title
                acf {
                  description
                  contact_people {
                    company
                    name
                    job_title
                    phone_number
                    email
                  }
                }
              }
            }
          }
        }
      `}
      render={props => {
        const { acf, title } = props.allWordpressWpMediaContact.edges[0].node
        return (
          <Section title={title} description={acf.description}>
            <ul className="media-contact-cards">
              {acf.contact_people.map((person, i) => (
                <li className="media-contact-card" key={i}>
                  <div className="media-contact-company">{person.company}</div>
                  <div className="media-contact-name">{person.name}</div>
                  <div className="media-contact-jobtitle">
                    {person.job_title}
                  </div>
                  <div className="media-contact-phone-and-email">
                    <a
                      className="media-contact-phone"
                      href={"tel:" + person.phone_number.replace(/ /g, "")}
                    >
                      {person.phone_number}
                    </a>
                    <a
                      className="media-contact-email"
                      href={"mailto:" + person.email}
                    >
                      {person.email}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </Section>
        )
      }}
    />
  )
}

export default MediaContact
