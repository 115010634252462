import React from "react"
import "./background-style.scss"

const Background = () => {
  return (
    <>
      <div className="background-wrapper">
        <svg
          className="mountain"
          style={{ height: "8500px" }}
          viewBox="0 0 848 656"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M424 0l67.477 119.895L518.5 72 848 656H0L329.5 72l27.023 47.895L424 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
        <div
          className="background"
          style={{
            height: "8500px",
          }}
        ></div>
      </div>
    </>
  )
}

export default Background
