import React, { useContext } from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../Section/Section"
import { ModalContext } from "../../pages/index.js"
import "./tech-partners-style.scss"

const TechPartners = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpTechPartners {
            edges {
              node {
                title
                acf {
                  partner_industry
                  partner_tagline
                  partner_bio
                  partner_website
                  partner_facebook
                  partner_linkedin
                  partner_twitter
                  partner_instagram
                }
                featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 240) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
          allWordpressWpDescriptionTexts {
            edges {
              node {
                title
                acf {
                  description
                }
              }
            }
          }
        }
      `}
      render={props => {
        const value = useContext(ModalContext)

        const handleOpenPartner = (
          image,
          title,
          tagline,
          bio,
          industry,
          website,
          platforms
        ) => {
          value.setModalState({
            isOpen: true,
            selectedPartner: {
              image,
              title,
              tagline,
              bio,
              industry,
              website,
              platforms,
            },
          })
        }

        const {
          title,
          acf,
        } = props.allWordpressWpDescriptionTexts.edges[2].node

        return (
          <Section title={title} description={acf.description}>
            <ul className="partners-cards">
              {props.allWordpressWpTechPartners.edges.map((partner, i) => {
                const {
                  partner_bio,
                  partner_tagline,
                  partner_industry,
                  partner_website,
                  partner_facebook,
                  partner_linkedin,
                  partner_twitter,
                  partner_instagram,
                } = partner.node.acf
                const image =
                  partner.node.featured_media.localFile.childImageSharp.fluid

                const platforms = {
                  facebook: partner_facebook,
                  linkedin: partner_linkedin,
                  twitter: partner_twitter,
                  instagram: partner_instagram,
                }

                return (
                  <li
                    className="partner-card"
                    style={{
                      width: `${100 /
                        props.allWordpressWpTechPartners.edges.length}%`,
                    }}
                    key={i}
                    onClick={() =>
                      handleOpenPartner(
                        image,
                        partner.node.title,
                        partner_tagline,
                        partner_bio,
                        partner_industry,
                        partner_website,
                        platforms
                      )
                    }
                  >
                    <div className="parter-image-wrapper">
                      <Img className="partner-image" fluid={image} />
                    </div>
                    <h3 className="partner-title">{partner.node.title}</h3>
                    <p className="partner-tagline">{partner_tagline}</p>
                    <span className="partner-learn-more link" href="#">
                      Learn more
                    </span>
                  </li>
                )
              })}
            </ul>
          </Section>
        )
      }}
    />
  )
}

export default TechPartners
