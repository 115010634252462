import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../Section/Section"
import "./supporting-partners-style.scss"

const SupportingPartners = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpSupportingPartners {
            nodes {
              title
              featured_media {
                localFile {
                  childImageSharp {
                    fixed(width: 180) {
                      base64
                      tracedSVG
                      aspectRatio
                      width
                      height
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      originalName
                    }
                  }
                }
              }
            }
          }
          allWordpressWpDescriptionTexts {
            edges {
              node {
                title
                acf {
                  description
                }
              }
            }
          }
        }
      `}
      render={props => {
        if (!props.allWordpressWpSupportingPartners) return null

        const {
          nodes: supportingPartners,
        } = props.allWordpressWpSupportingPartners
        const {
          title: sectionTitle,
          acf,
        } = props.allWordpressWpDescriptionTexts.edges[0].node

        return (
          <Section title={sectionTitle} description={acf.description}>
            <ul className="supporting-partners-cards">
              {supportingPartners.map((partner, i) => {
                return (
                  <li className="supporting-partner-card" key={i}>
                    <div className="supporting-partner-image-wrapper">
                      <Img
                        className="supporting-partner-image"
                        fluid={
                          partner.featured_media.localFile.childImageSharp.fixed
                        }
                      />
                    </div>
                  </li>
                )
              })}
            </ul>
          </Section>
        )
      }}
    />
  )
}

export default SupportingPartners
