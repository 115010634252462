import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../Section/Section"
import Social from "../Social/Social"
import { getDisplayName } from "../../utils/utils"
import "./main-partner-style.scss"

const MainPartner = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allWordpressWpMainPartner {
          edges {
            node {
              acf {
                tagline
                bio
                website
                facebook
                linkedin
                twitter
                instagram
              }
              title
              featured_media {
                localFile {
                  childImageSharp {
                    fixed(width: 374, height: 203) {
                      base64
                      tracedSVG
                      aspectRatio
                      width
                      height
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const {
    tagline,
    bio,
    website,
    facebook,
    linkedin,
    twitter,
    instagram,
  } = query.allWordpressWpMainPartner.edges[0].node.acf

  const socialProps = { facebook, instagram, linkedin, twitter }

  return (
    <Section title={"Main Partner"} id={"partners"}>
      <div className="main-partner">
        <Img
          className="main-partner-image main-partner-logo"
          fixed={
            query.allWordpressWpMainPartner.edges[0].node.featured_media
              .localFile.childImageSharp.fixed
          }
        />
        <div className="main-partner-content">
          <h4 className="main-partner-tagline">{tagline}</h4>
          <p className="main-partner-bio">{bio}</p>
          <a className="main-partner-website" href={website}>
            {getDisplayName(website)}
          </a>
          <Social platforms={socialProps} />
        </div>
      </div>
    </Section>
  )
}

export default MainPartner
