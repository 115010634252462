import React from "react"
import Background from "./Background/Background"
import Cookies from "./Cookies/Cookies"
import Footer from "./Footer/Footer"
import Header from "./Header/Header"
import "../styles/global.scss"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Cookies />
      <main>{children}</main>
      <Footer />
      <Background />
    </>
  )
}

export default Layout
