import React, { useEffect, useState, useRef } from "react"
import c from "classnames"
import { animated as a, useSpring } from "react-spring"
import Section from "../Section/Section"
import { graphql, useStaticQuery } from "gatsby"
import "./newsletter-style.scss"

const Newsletter = () => {
  const { allWordpressWpDescriptionTexts } = useStaticQuery(
    graphql`
      query {
        allWordpressWpDescriptionTexts {
          edges {
            node {
              title
              acf {
                description
              }
            }
          }
        }
      }
    `
  )

  const checkboxRef = useRef()
  const [emailValue, setEmailValue] = useState("")
  const [nameValue, setNameValue] = useState("")
  const [jobTitleValue, setJobTitleValue] = useState("")
  const [professionValue, setProfessionValue] = useState("")
  const [fieldsContainText, setFieldsContainText] = useState(false)
  const [isChecked, setChecked] = useState(false)
  const [inFocus, setInFocus] = useState({
    email: false,
    name: false,
    jobTitle: false,
    profession: false,
  })

  const fade = useSpring({
    opacity: fieldsContainText ? 1 : 0,
  })

  useEffect(() => {
    if (
      emailValue.length > 0 &&
      professionValue.length > 0 &&
      jobTitleValue.length > 0 &&
      nameValue.length > 0
    ) {
      setFieldsContainText(true)
    } else {
      setFieldsContainText(false)
      setChecked(false)
    }
  }, [emailValue, professionValue, jobTitleValue, nameValue])

  const handleKeyDownCheck = e => {
    if (e.code === "Tab" || e.which === 9 || e.key === "Tab") {
    } else {
      e.preventDefault()
      if (
        e.code === "Space" ||
        e.which === 32 ||
        e.key === "Space" ||
        e.code === "Enter" ||
        e.which === 13 ||
        e.key === "Enter"
      ) {
        setChecked(!isChecked)
      }
    }
  }

  const handleChecked = () => {
    const { checked } = checkboxRef.current
    if (checked) setChecked(true)
    else setChecked(false)
  }

  const { title, acf } = allWordpressWpDescriptionTexts.edges[1].node

  return (
    <Section id="newsletter" title={title} description={acf.description}>
      <div id="mc_embed_signup" className="newsletter">
        <form
          action="https://digitalhubdenmark.us20.list-manage.com/subscribe/post?u=9c77e3d1847bcd6bf15535aee&amp;id=b502ebbaae"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          rel="noopener noreferrer"
          noValidate
        >
          <div id="mc_embed_signup_scroll" className="newsletter-body">
            <div className="newsletter-inputs-flex row">
              <div className="newsletter-input email">
                <label
                  htmlFor="mce-EMAIL"
                  className={c(
                    (inFocus.email || emailValue.length > 0) && "infocus"
                  )}
                ></label>
                <input
                  type="email"
                  value={emailValue}
                  onChange={e => setEmailValue(e.target.value)}
                  name="EMAIL"
                  className="email"
                  id="mce-EMAIL"
                  onFocus={() => setInFocus({ ...inFocus, email: true })}
                  onBlur={() => setInFocus({ ...inFocus, email: false })}
                  required
                />
              </div>
              <div className="newsletter-input name">
                <label
                  htmlFor="mce-FNAME"
                  className={c(
                    (inFocus.name || nameValue.length > 0) && "infocus"
                  )}
                ></label>
                <input
                  type="text"
                  value={nameValue}
                  onChange={e => setNameValue(e.target.value)}
                  name="FNAME"
                  className="name"
                  id="mce-FNAME"
                  onFocus={() => setInFocus({ ...inFocus, name: true })}
                  onBlur={() => setInFocus({ ...inFocus, name: false })}
                  required
                />
              </div>
            </div>
            <div className="newsletter-inputs-flex row">
              <div className="newsletter-input jobtitle">
                <label
                  htmlFor="mce-MMERGE6"
                  className={c(
                    (inFocus.jobTitle || jobTitleValue.length > 0) && "infocus"
                  )}
                ></label>
                <input
                  type="text"
                  value={jobTitleValue}
                  onChange={e => setJobTitleValue(e.target.value)}
                  name="MMERGE6"
                  className="jobtitle"
                  id="mce-MMERGE6"
                  onFocus={() => setInFocus({ ...inFocus, jobTitle: true })}
                  onBlur={() => setInFocus({ ...inFocus, jobTitle: false })}
                  required
                />
              </div>
              <div className="newsletter-input company">
                <label
                  htmlFor="mce-MMERGE5"
                  className={c(
                    (inFocus.profession || professionValue.length > 0) &&
                      "infocus"
                  )}
                ></label>
                <input
                  type="text"
                  value={professionValue}
                  onChange={e => setProfessionValue(e.target.value)}
                  name="MMERGE5"
                  className="company"
                  id="mce-MMERGE5"
                  onFocus={() => setInFocus({ ...inFocus, profession: true })}
                  onBlur={() => setInFocus({ ...inFocus, profession: false })}
                  required
                />
              </div>
            </div>
            <a.div
              style={fade}
              id="mergeRow-gdpr"
              className="newsletter-inputs-flex column newsletter-gdpr"
            >
              <div className="content__gdpr">
                <fieldset name="interestgroup_field">
                  <input
                    ref={checkboxRef}
                    type="checkbox"
                    id="gdpr_32097"
                    name="gdpr[32097]"
                    className="av-checkbox"
                    onChange={() => handleChecked()}
                    onKeyDown={e => handleKeyDownCheck(e)}
                    required
                  />
                  <label
                    className={c("checkbox", isChecked && "checkbox-checked")}
                    htmlFor="gdpr_32097"
                  />
                  <div className="gdpr-text">
                    I give consent to Digital Hub Denmark to contact me. You can
                    opt out anytime at the bottom of each newsletter.
                    <br />
                    <br />
                    Learn more about Mailchimp's privacy practices
                    <a
                      href="https://mailchimp.com/legal/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </div>
                </fieldset>
              </div>
            </a.div>
            <div
              className={c(
                "button",
                "newsletter-submit-button",
                fieldsContainText && "move-button",
                fieldsContainText && isChecked && "gdpr-checked"
              )}
              // style={submitButtonTransitions}
            >
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_9c77e3d1847bcd6bf15535aee_b502ebbaae"
                  tabIndex="-1"
                />
              </div>
              <input
                value="Sign up"
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
              />
            </div>
          </div>
        </form>
      </div>
    </Section>
  )
}

export default Newsletter
