import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO() {
  const query = useStaticQuery(
    graphql`
      query {
        allWordpressSiteMetadata {
          edges {
            node {
              description
              name
            }
          }
        }
      }
    `
  )

  const { description, name } = query.allWordpressSiteMetadata.edges[0].node
  const author = "Digital Hub Denmark"

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={name}
      titleTemplate={`%s | 🇩🇰 @ websummit`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: name,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: name,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat([])}
    />
  )
}

export default SEO
