import React from "react"
import { isMobile } from "react-device-detect"
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu"
import MainMenu from "../MainMenu/MainMenu"
import Scrollchor from "react-scrollchor"
import { scrollAnimation } from "../../constants/constants"
// import { ActiveSectionContext } from "../../pages/index.js"
import "./header-style.scss"

const Header = () => {
  return (
    <>
      <HamburgerMenu />
      <header>
        <div className="header">
          <div>
            <Scrollchor to="" animate={scrollAnimation} className={"logo"}>
              <Logo />
            </Scrollchor>
          </div>
          {!isMobile && <MainMenu />}
        </div>
      </header>
    </>
  )
}

export const Logo = () => {
  return (
    <>
      <div className="flag">
        <svg
          width="83"
          height="59"
          viewBox="0 0 83 59"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g transform="translate(-484 -1400)" fill="none" fillRule="evenodd">
            <rect
              fill="#E10B35"
              x="484"
              y="1400"
              width="83"
              height="59"
              rx="1"
            />
            <path fill="#EFEFEF" d="M508 1400h6v59h-6z" />
            <path fill="#EFEFEF" d="M484 1433v-6h83v6z" />
          </g>
        </svg>
      </div>
      <span className="at-sign">@</span> <span>web</span>
      <strong style={{ marginRight: "4px" }}>summit</strong>2019
    </>
  )
}

export default Header
