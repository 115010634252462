import React, { useEffect, useState } from "react"
import c from "classnames"
import "./social-style.scss"

const Social = ({ platforms }) => {
  const [socials, setSocials] = useState()

  const { facebook, instagram, linkedin, twitter } = platforms

  useEffect(() => {
    let socialsArray = []

    if (facebook.length > 0)
      socialsArray.push({ platform: "facebook", url: facebook })
    if (instagram.length > 0)
      socialsArray.push({ platform: "instagram", url: instagram })
    if (linkedin.length > 0)
      socialsArray.push({ platform: "linkedin", url: linkedin })
    if (twitter.length > 0)
      socialsArray.push({ platform: "twitter", url: twitter })

    setSocials(socialsArray)
  }, [])

  return (
    <ul className="main-partner-social social">
      {socials &&
        socials.map((social, i) => {
          // let firstLetter
          // let secondLetter

          // switch (social.platform) {
          //   case "facebook":
          //     firstLetter = "F"
          //     secondLetter = "b"
          //     break
          //   case "instagram":
          //     firstLetter = "I"
          //     secondLetter = "g"
          //     break
          //   case "linkedin":
          //     firstLetter = "L"
          //     secondLetter = "I"
          //     break
          //   case "twitter":
          //     firstLetter = "T"
          //     secondLetter = "w"
          //     break
          //   default:
          //     break
          // }

          return (
            <li className={c(social.platform, `social-item-${i}`)} key={i}>
              <a href={social.url} target="_blank" rel="noopener noreferrer">
                {social.platform}
              </a>
            </li>
          )
        })}
    </ul>
  )
}

export default Social
