import React from "react"
import "./upnext-and-program-style.scss"
import { Speaker } from "../Speakers/Speakers"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Scrollchor from "react-scrollchor"
import { scrollAnimation } from "../../constants/constants"
import {
  Accordion,
  AccordionItemButton,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import WhatsApp from "../WhatsApp/WhatsApp"

const UpNextANDProgram = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpSpeakers {
            edges {
              node {
                title
                acf {
                  speaker_title
                  speaker_company
                  speaker_industry
                  speaker_bio
                  speaker_website
                  speaker_video
                  speaker_facebook
                  speaker_instagram
                  speaker_linkedin
                  speaker_twitter
                }
                featured_media {
                  localFile {
                    childImageSharp {
                      fixed(width: 560, height: 640) {
                        base64
                        tracedSVG
                        aspectRatio
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
          allWordpressWpProgram {
            edges {
              node {
                acf {
                  talks {
                    event_link
                    has_speaker
                    location
                    time_end
                    title
                    time_start
                    speaker {
                      post_title
                    }
                    event_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 340, height: 410) {
                            base64
                            tracedSVG
                            aspectRatio
                            width
                            height
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            originalName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          allWordpressWpProgramThemes {
            edges {
              node {
                acf {
                  program_theme_date
                }
                title
              }
            }
          }
          allWordpressWpDownloadFiles {
            edges {
              node {
                acf {
                  download_programme {
                    url {
                      localFile {
                        url
                      }
                    }
                  }
                  download_floorplan {
                    url {
                      localFile {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={props => {
        if (!props) return null

        const { talks } = props.allWordpressWpProgram.edges[0].node.acf

        const talksAscending = talks.sort((a, b) => {
          let x = Number(a.time_start.replace(/ /, "").replace(/:/, ""))
          let y = Number(b.time_start.replace(/ /, "").replace(/:/, ""))
          return x - y
        })

        let now = new Date().getTime()
        let closest = { ms: Infinity, data: null }

        // const nov2 = 1572728974075
        // const nov5 = 1572928974075
        // const nov6 = 1573008974075
        // const nov7 = 1573098974075

        talksAscending.forEach(d => {
          const day = d.time_start.split(" ")[0]
          const time = d.time_start.split(" ")[1]
          let date = new Date(`2019-11-${day}T${time}`)
          date = date.getTime()
          if (
            date >= now &&
            (date < new Date(closest.ms) || date < closest.ms)
          ) {
            closest = { ms: date, data: d }
          }
        })

        const { edges } = props.allWordpressWpSpeakers

        let findSpeaker

        if (
          closest &&
          closest.data &&
          closest.data.speaker &&
          closest.data.speaker[0]
        ) {
          findSpeaker = edges.find(
            speaker => speaker.node.title === closest.data.speaker[0].post_title
          )
        }

        const themesAscending = props.allWordpressWpProgramThemes.edges.sort(
          (a, b) =>
            a.node.acf.program_theme_date - b.node.acf.program_theme_date
        )

        const dateToday = new Date().getDate()
        let expandDay =
          (dateToday === 4) |
          (dateToday === 5) |
          (dateToday === 6) |
          (dateToday === 7)
            ? [`${dateToday}`]
            : ["4"]

        const {
          download_floorplan,
          download_programme,
        } = props.allWordpressWpDownloadFiles.edges[0].node.acf

        console.log("talllllks", talks)

        return (
          <section id="upnext-and-programme" className="upnext-and-programme">
            <div className="upnext">
              <h2 className="section-title left-align">Up Next</h2>
              <div className="upnext-speaker-wrapper">
                <span>
                  {/* {closest.data.time_start.split(" ")[1]}-
                  {closest.data.time_end} <span className="at-sign">@</span>{" "}
                  {closest.data.location} */}
                  {talks[23].time_start.split(" ")[1]}-{talks[23].time_end}{" "}
                  <span className="at-sign">@</span> {talks[23].location}
                </span>
                <h4>{talks[23].title}</h4>
                {talks[23].has_speaker === "Yes" ? (
                  <Speaker speaker={findSpeaker.node} />
                ) : (
                  <Img
                    fixed={
                      talks[23].event_image.localFile.childImageSharp.fixed
                    }
                  />
                )}
              </div>
            </div>
            <div className="programme" id="programme">
              <h2 className="section-title left-align">Programme</h2>
              <div className="programme-wrapper">
                <Accordion
                  allowZeroExpanded
                  preExpanded={expandDay}
                  allowMultipleExpanded={true}
                >
                  {themesAscending.map((theme, i) => {
                    const { title } = theme.node
                    const { program_theme_date } = theme.node.acf
                    return (
                      <AccordionItem key={i} uuid={program_theme_date}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {title}
                            {/* <div>{program_theme_date}th</div> */}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="programme-questions">
                            <li className="programme-item-when">When?</li>
                            <li className="programme-item-what">What?</li>
                            <li className="programme-item-where-question">
                              Where?
                            </li>
                            {/* <li className="programme-item-how">How?</li> */}
                          </ul>
                          <ul className="programme-items-content">
                            {talks.map((talk, i) => {
                              const {
                                time_start,
                                time_end,
                                title,
                                has_speaker,
                                speaker,
                                location,
                                event_link,
                              } = talk
                              if (
                                Number(talk.time_start.split(" ")[0]) ===
                                Number(program_theme_date)
                              ) {
                                return (
                                  <>
                                    <li className="programme-item" key={i}>
                                      <div className="programme-item-when">
                                        {time_start.split(" ")[1]}-<br />
                                        {time_end}
                                      </div>
                                      <div className="programme-item-what">
                                        <h4>{title}</h4>
                                        {has_speaker === "Yes" && (
                                          <>
                                            <span>by </span>
                                            <Scrollchor
                                              to="speakers"
                                              animate={scrollAnimation}
                                            >
                                              {speaker.map((each, i) => {
                                                return i > 0
                                                  ? ` & ${each.post_title}`
                                                  : each.post_title
                                              })}
                                            </Scrollchor>
                                          </>
                                        )}
                                      </div>
                                      <div className="programme-item-where">
                                        {location}
                                        <br />
                                        {event_link && (
                                          <a
                                            className="programme-item-how-mobile"
                                            href={event_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Sign up
                                          </a>
                                        )}
                                      </div>
                                      {/* <div className="programme-item-how">
                                        {event_link && (
                                          <a
                                            href={event_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Sign up
                                          </a>
                                        )}
                                      </div> */}
                                    </li>
                                  </>
                                )
                              } else return null
                            })}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    )
                  })}
                </Accordion>
              </div>
              {(download_floorplan || download_programme) && (
                <div className="programme-downloads">
                  {download_programme && (
                    <a
                      type="download"
                      href={download_programme.url.localFile.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Program
                    </a>
                  )}
                  {download_floorplan && (
                    <a
                      type="download"
                      href={download_floorplan.url.localFile.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Floor Plan
                    </a>
                  )}
                </div>
              )}
              <WhatsApp />
            </div>
          </section>
        )
      }}
    />
  )
}

export default UpNextANDProgram
