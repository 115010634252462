import React, { useContext, useEffect, useState } from "react"
import c from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import { animated, useTrail } from "react-spring"
import Scrollchor from "react-scrollchor"
import { scrollAnimationMobile } from "../../constants/constants"
import { ActiveSectionContext } from "../../pages/index.js"
import { Logo } from "../Header/Header"
import "./hamburger-menu-style.scss"

const HamburgerToX = ({ showMenu, setShowMenu, isLeft }) => (
  <button
    type="button"
    className={c("icon", isLeft && "left-hand")}
    onClick={setShowMenu}
  >
    <div className={c("hamburger", showMenu && "open")}>
      <span />
      <span />
      <span />
    </div>
  </button>
)

const HamburgerMenu = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allWordpressWpApiMenusMenusItems(
          filter: { name: { eq: "Main menu" } }
        ) {
          edges {
            node {
              items {
                title
                url
              }
            }
          }
        }
      }
    `
  )

  const [showMenu, setShowMenu] = useState()

  const { items } = query.allWordpressWpApiMenusMenusItems.edges[0].node

  const trail = useTrail(items.length + 1, {
    opacity: showMenu ? 1 : 0,
    transform: showMenu ? "scale(1)" : "scale(0.9)",
  })

  const value = useContext(ActiveSectionContext)

  const handleSelectMenuItem = item => {
    setShowMenu(false)
    value.setActiveSection(item ? item : "#newsletter")
  }

  const [isLeft, setUseLeft] = useState()

  useEffect(() => {
    setUseLeft(
      typeof window !== "undefined" &&
        window.localStorage.getItem("leftHandMode") === "true"
        ? true
        : false
    )
  }, [])

  const handleUseLeft = () => {
    setUseLeft(!isLeft)
    if (isLeft) {
      localStorage.setItem("leftHandMode", false)
    }
    if (!isLeft) {
      localStorage.setItem("leftHandMode", true)
    }
  }

  return (
    <>
      <HamburgerToX
        showMenu={showMenu}
        setShowMenu={() => setShowMenu(!showMenu)}
        isLeft={isLeft}
      />
      <div
        className={c(
          "hamburger-menu",
          showMenu && "hamburger-menu-show",
          isLeft ? "hamburger-menu-left-hand" : "hamburger-menu-right-hand"
        )}
      >
        <div className="hamburger-menu-logo">
          <Scrollchor
            to="#"
            animate={scrollAnimationMobile}
            beforeAnimate={() => handleSelectMenuItem("#")}
            disableHistory
          >
            <Logo />
          </Scrollchor>
        </div>
        <ul className="hamburger-menu-items">
          {trail.map((animation, i) => {
            return (
              <animated.li
                style={animation}
                className={c(items[i] && "hamburger-menu-item")}
              >
                {items[i] ? (
                  <Scrollchor
                    to={items[i].url}
                    animate={scrollAnimationMobile}
                    beforeAnimate={() => handleSelectMenuItem(items[i].url)}
                    key={items[i].title}
                    // disableHistory
                  >
                    {items[i].title}
                  </Scrollchor>
                ) : (
                  <Scrollchor
                    to="newsletter"
                    animate={scrollAnimationMobile}
                    beforeAnimate={() => handleSelectMenuItem()}
                    className="hamburger-menu-newsletter-button"
                    // disableHistory
                  >
                    Newsletter
                  </Scrollchor>
                )}
              </animated.li>
            )
          })}
          <div
            className={c("left-or-right-btn-wrapper", isLeft && "left-hand")}
          >
            <button onClick={() => handleUseLeft()}>
              Use <span>{isLeft ? "right" : "left"}</span> hand
            </button>
          </div>
        </ul>
      </div>
    </>
  )
}

export default HamburgerMenu
