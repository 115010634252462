import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../Section/Section"
import "./startups-style.scss"

const Startups = () => {
  const truncate = text => {
    if (text.length > 140) return text.substring(0, 140) + "..."
    else return text
  }

  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpStartups {
            nodes {
              title
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            edges {
              node {
                acf {
                  startup_bio
                  startup_team_photo
                  startup_website
                  startup_website_display_name
                  startup_contact_person
                  startup_contact_persons_email
                }
              }
            }
          }
          allWordpressWpDescriptionTexts {
            edges {
              node {
                title
                acf {
                  description
                }
              }
            }
          }
        }
      `}
      render={props => {
        const { edges, nodes } = props.allWordpressWpStartups

        const {
          title,
          acf,
        } = props.allWordpressWpDescriptionTexts.edges[3].node

        return (
          <Section title={title} description={acf.description}>
            <ul className="startups-cards">
              {edges.map((startup, i) => {
                const {
                  startup_bio,
                  startup_website,
                  startup_website_display_name,
                  startup_contact_person,
                  startup_contact_persons_email,
                } = startup.node.acf
                return (
                  <li className="startup-card" key={i}>
                    <Img
                      className="startup-image"
                      fluid={
                        nodes[i].featured_media.localFile.childImageSharp.fluid
                      }
                    />
                    <h3 className="startup-title">{nodes[i].title}</h3>
                    {/* <span className="startup-industry">{startup_industry}</span> */}
                    <p className="startup-bio">{truncate(startup_bio)}</p>
                    <div className="startup-links">
                      <div>
                        <span>Founder</span>
                        <a href={"mailto:" + startup_contact_persons_email}>
                          {startup_contact_person}
                        </a>
                      </div>
                      <div>
                        <span>Website</span>
                        <a
                          className="startup-website"
                          href={startup_website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {startup_website_display_name}
                        </a>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </Section>
        )
      }}
    />
  )
}

export default Startups
