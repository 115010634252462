import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { animated as a, useSpring } from "react-spring"
import "./intro-style.scss"
import Scrollchor from "react-scrollchor"
import { scrollAnimation } from "../../constants/constants"
import Accordion from "../Accordion/Accordion"

const Intro = () => {
  const move = useSpring({
    from: { opacity: 0 },
    opacity: 1,
    config: { duration: 1500 },
  })
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpIntro {
            edges {
              node {
                title
                acf {
                  intro_title_subtitle
                  intro_value_proposition
                  read_more_button_text
                  read_more_text
                  newsletter_button_text
                }
              }
            }
          }
        }
      `}
      render={props => {
        const { title, acf } = props.allWordpressWpIntro.edges[0].node

        const markup = acf.intro_value_proposition.replace(
          /a href/g,
          `a target="_blank" rel="noopener noreferrer" href`
        )

        return (
          <section id="intro">
            <a.div style={move} className="intro-texts">
              <h1 className="intro-title">{title}</h1>
              <span className="intro-subtitle">{acf.intro_title_subtitle}</span>
              <div
                className="intro-description"
                dangerouslySetInnerHTML={{
                  __html: acf.intro_value_proposition,
                }}
              />
              {/* <p className="intro-description">
                <Scrollchor
                  to={"#partners"}
                  animate={scrollAnimation}
                  disableHistory
                >
                  Digital Hub Denmark
                </Scrollchor>{" "}
                and a delegation of Danish{" "}
                <Scrollchor
                  to={"#startups"}
                  animate={scrollAnimation}
                  disableHistory
                >
                  startups
                </Scrollchor>{" "}
                will travel to websummit 2019 to showcase the best of the best
                within Danish tech and innovation at one of the world’s biggest
                tech conferences.
              </p> */}
              <Accordion
                buttonText={acf.read_more_button_text}
                text={acf.read_more_text}
              />
              <Scrollchor
                className="intro-cta"
                to={"#newsletter"}
                animate={scrollAnimation}
                disableHistory
              >
                {acf.newsletter_button_text}
              </Scrollchor>
            </a.div>
          </section>
        )
      }}
    />
  )
}

export default Intro
