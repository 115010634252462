import { easeInOutQuart } from "react-scrollchor"

export const scrollAnimation = {
  offset: -100,
  duration: 600,
  easing: easeInOutQuart,
}

export const scrollAnimationMobile = {
  offset: -30,
  duration: 600,
  easing: easeInOutQuart,
}
