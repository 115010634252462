import React, { useContext, useEffect } from "react"
import { useTransition, animated as a } from "react-spring"
import Img from "gatsby-image"
import Social from "../Social/Social"
import { getDisplayName } from "../../utils/utils"
import "./modal-style.scss"
import { ModalContext } from "../../pages/index.js"

const Modal = () => {
  const value = useContext(ModalContext)

  const transitions = useTransition(value.isOpen, null, {
    from: { position: "absolute", zIndex: 10, opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    if (value.isOpen) document.body.style.overflow = "hidden"
    else document.body.style.overflow = "auto"
  }, [value.isOpen])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDownClose, false)
    return () => {
      document.removeEventListener("keydown", handleKeyDownClose, false)
    }
  }, [])

  const handleKeyDownClose = e => {
    if (
      e === "button" ||
      e.code === "Escape" ||
      e.which === 27 ||
      e.key === "Escape" ||
      e.target.className === "modal"
    ) {
      value.setModalState({
        isOpen: false,
        YouTubeURL: null,
        selectedPartner: null,
      })
    }
  }

  if (value.YouTubeURL) {
    return transitions.map(
      ({ item, key, props }) =>
        item && (
          <a.div
            key={key}
            style={props}
            onClick={() =>
              value.setModalState({ isOpen: false, YouTubeURL: null })
            }
          >
            <div className="modal vertical-align">
              <div className="video-embed-wrapper">
                <iframe
                  title="iframe"
                  src={value.YouTubeURL}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </a.div>
        )
    )
  } else if (value.selectedPartner) {
    const {
      image,
      title,
      tagline,
      bio,
      industry,
      website,
      platforms,
    } = value.selectedPartner
    return transitions.map(
      ({ item, key, props }) =>
        item && (
          <a.div key={key} style={props} onClick={e => handleKeyDownClose(e)}>
            <div className="modal">
              <div className="partner-wrapper">
                <button
                  className="close-modal-button"
                  onClick={() => handleKeyDownClose("button")}
                >
                  Close
                </button>
                <Img className="partner-image" fluid={image} />
                <h3 className="modal-title">{title}</h3>
                <h4 className="modal-industry">{industry}</h4>
                <p className="modal-tagline">{tagline}</p>
                <p className="modal-bio">{bio}</p>
                <a
                  className="modal-website"
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getDisplayName(website)}
                </a>
                <Social platforms={platforms} />
              </div>
            </div>
          </a.div>
        )
    )
  } else return null
}

export default Modal
